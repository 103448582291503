<template>
  <figure class="home">
    <img class="mobile" src="../assets/mobile.jpeg" alt="">
    <img class="desktop" src="../assets/desktop.jpeg" alt="">
  </figure>
</template>

<script>
  export default {
    name: 'HomeView'
  }
</script>

<style lang="scss">
  html, body, figure {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
  }
  @media only screen and (min-width: 0) {
    .desktop {
      display: none;
    }
  }
  @media only screen and (min-width: 1024px) {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
</style>
